import React, { Component } from "react"
import { css } from "@emotion/react"

import { OverlayOpen } from "libs/redux/action"
import { graphql } from "gatsby"

import * as Store from "../components/store"
import * as Modules from "../components/modules"
import * as Libs from "libs"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { connect } from "react-redux"

class PostShop extends Component {
  //--------------------------------------
  //  Libs.Styles
  //--------------------------------------

  articleHeaderCss = () => {
    let lessPabCss = null
    if (this.state.ready) {
      lessPabCss = css`
        height: ${this.articleHeader.clientHeight}px;
      `
    }
    return css`
      ${Libs.Styles.Mq.lessPab} {
        ${lessPabCss}
      }
    `
  }
  blocks = () =>
    css`
      margin-top: 10vw;

      margin-left: auto;
      margin-right: auto;
    `

  imgContCss = aspectRatio => {
    const heightValue = this.state.imgContHeight
    if (aspectRatio <= 1) {
      return css`
        height: 100vh;
        ${Libs.Styles.Mq.moreTab} {
          height: 100vh;
          margin-left: auto;
          margin-right: auto;
          .gatsby-image-wrapper {
            height: 100%;
            img {
              object-fit: contain !important;
            }
          }
        }
        ${Libs.Styles.Mq.lessPab} {
          height: ${heightValue}px;
          .gatsby-image-wrapper {
            height: ${heightValue}px;
          }
        }
      `
    }
  }

  inlineAncher = () =>
    css`
      ${Libs.Styles.Mixin.textAncher()}
    `
  //--------------------------------------
  //  Scripts
  //--------------------------------------

  constructor(props) {
    super(props)

    // console.log("data ---", this.props, productInfo)
    this.articleHeader = React.createRef()
    /*
    this.imgSrc = WindowResize.currentType(WindowResize.lessPab)
      ? productInfo.headerImage.imageSp
        ? productInfo.headerImage.imageSp
        : productInfo.headerImage.image
      : productInfo.headerImage.image
    this.ratio = this.imgSrc.sizes.aspectRatio*/
    this.state = {
      ready: false,
      display: false,
    }
  }

  componentDidMount() {
    Libs.Common.Config.excuteTransition()
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }
  render() {
    const { data, pageContext } = this.props
    const productInfo = data.contentfulContentShop
    const product = data.shopifyProductVariant.product

    const { next, prev } = pageContext
    const lang = productInfo.node_locale
    let productAvailable = product.availableForSale

    const vendor = product.vendor
    if (lang === "en") {
      if (vendor === "HowltCoffee" || vendor === "openlogi") {
        productAvailable = false
      }
    }
    const price = Libs.Common.StoreFunc.getPrice(
      data.shopifyProductVariant.presentmentPrices.edges
    )

    const buttonCss = () => css`
      position: absolute;
      z-index: 10000;
      transform: translateX(-50%);
      left: 50%;
      transition: opacity 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      opacity: 0;

      .on-story & {
        opacity: 1;
      }
      .on-pause & {
        opacity: 0;
      }
      .now-transition & {
        transition: opacity 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        opacity: 0;
      }
      ${Libs.Styles.Mq.moreTab} {
        top: 115px;
        width: 300px;
        button {
          width: 100%;
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        bottom: 8%;
        width: 88vw;
        button {
          width: 88vw;
        }
      }
    `
    const infoCss = () => css`
      position: absolute;
      z-index: 1;
      top: 150vh;
    `
    const clickHandler = () => {
      const { dispatch } = this.props

      dispatch(
        OverlayOpen({
          element: Store.ProductForm,
          props: { product, productInfo, productAvailable },
        })
      )
      return false
    }
    let schemaOrgJSONLDList = []
    const isComingSoon = new Date(productInfo.date) > new Date()

    schemaOrgJSONLDList.push(
      Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD(
        [
          { name: "Home", url: `/${lang}/` },
          {
            name: "Shop",
            url: `/${lang}/${productInfo.category.slug}/`,
          },
          {
            name: productInfo.name,
            title: productInfo.title,
            url: `/${lang}/${productInfo.category.slug}/${productInfo.slug}/`,
          },
        ],
        {
          next: `/${lang}/${productInfo.category.slug}/${next.slug}/`,
          prev: `/${lang}/${productInfo.category.slug}/${prev.slug}/`,
        }
      )
    )
    return (
      <>
        <Libs.Common.SEO.SEO
          title={productInfo.title ? productInfo.title : productInfo.name}
          lang={lang}
          schemaOrgJSONLD={schemaOrgJSONLDList}
          description={
            productInfo.description
              ? documentToReactComponents(
                  JSON.parse(productInfo.description.raw)
                )[0].props.children[0]
              : productInfo.title
          }
          ogImage={productInfo.thumbnail.fluid.src}
          link={`${productInfo.category.slug}/${productInfo.slug}`}
        />

        <section
          className="article project-container"
          data-prev={prev.slug}
          data-next={next.slug}
          data-current={productInfo.slug}
          data-currentname={productInfo.name}
          data-categoryname={productInfo.category.name}
          data-categoryslug={productInfo.category.slug}
          data-type={"post"}
        >
          <Libs.Atoms.ButtonElement
            css={buttonCss}
            onClick={() => {
              clickHandler()
              return false
            }}
            /* disabled={isComingSoon}*/
            spanStyles={{
              fontPc: {
                fontSize: 18,
                fontFace: "sansserif",
                bold: true,
                letterSpacing: 0.5,
              },
              fontSp: {
                fontSize: 18,
                fontFace: "sansserif",
                bold: true,
                letterSpacing: 0.5,
              },
            }}
          >
            {isComingSoon
              ? price + " / Coming Soon"
              : productAvailable
              ? price
              : price + " / SOLD OUT"}
          </Libs.Atoms.ButtonElement>

          <article className="container content">
            <Modules.StoryElement
              story={productInfo.story}
              next={next.slug}
              prev={prev.slug}
              category={productInfo.category}
            />
          </article>
          <div css={infoCss}>
            <Libs.Atoms.H1
              styles={{
                fontPc: { fontSize: 48, lineHeight: 48 },
                fontSp: { fontSize: 32, lineHeight: 32 },
              }}
            >
              {productInfo.title}
            </Libs.Atoms.H1>
            <Libs.Atoms.RichText
              styles={{
                fontPc: {
                  fontSize: 14,
                  fontFace: "sansserif",
                  lineHeight: "24",
                },
                fontSp: {
                  fontSize: 14,
                  fontFace: "sansserif",
                  lineHeight: "24",
                },
              }}
            >
              {productInfo.productDescription}
            </Libs.Atoms.RichText>
          </div>
        </section>
      </>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(PostShop)

export const pageQuery = graphql`
  query shopQuery($id: String!, $shopifyId: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulContentShop(id: { eq: $id }) {
      node_locale
      slug
      name
      title
      shopifyId
      date
      options
      description {
        raw
      }
      productDescription {
        raw
        references {
          ... on ContentfulElementButton {
            contentful_id
            __typename
            ...Button
          }
        }
      }
      thumbnail {
        fluid(maxWidth: 400, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      story {
        items {
          ... on ContentfulElementImage {
            ...Image
          }
        }
      }
      category {
        name
        slug
      }
      tags {
        name
        slug
      }
      variantColor
      variantName
      variantReferenceType
      variantReference {
        slug
        thumbnail {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        variantColor
        variantName
      }
    }
    shopifyProductVariant(shopifyId: { eq: $shopifyId }) {
      id
      shopifyId
      price
      presentmentPrices {
        edges {
          node {
            price {
              amount
              currencyCode
            }
          }
        }
      }
      product {
        id
        title
        handle
        productType
        description
        descriptionHtml
        shopifyId
        availableForSale
        vendor
        options {
          id
          name
          values
        }
        variants {
          id
          title
          price
          presentmentPrices {
            edges {
              node {
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`
